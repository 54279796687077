.projects {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 10rem;

  .body {
    max-width: 1400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
  }

  .modalContainer {
    height: 550px;
    width: 400px;
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: white;
    pointer-events: none;
    overflow: hidden;
    z-index: 3;
  }

  .modalSlider {
    height: 100%;
    width: 100%;
    position: relative;
    transition: top 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  }

  .modal {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .cursor,
  .cursorLabel {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: rgb(239 99 48);
    color: white;
    position: fixed;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 300;
    pointer-events: none;
  }

  .cursorLabel {
    background-color: transparent;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
  padding-bottom: 2rem;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    h4 {
      text-transform: uppercase;
      color: rgb(239 99 48);
      margin-bottom: 14px;
      font-size: 22px;
      font-weight: 500;
    }
    h2 {
      font-size: 29px;
      color: #46445c;
      font-weight: 500;
      margin-bottom: 16px;
    }
  }
}
