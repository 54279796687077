.project {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 50px 100px 50px 100px;
  border-top: 1px solid rgb(201, 201, 201);
  cursor: pointer;
  transition: all 0.2s;

  h2 {
    font-size: 60px;
    margin: 0px;
    font-weight: 400;
    transition: all 0.4s;
  }

  p {
    transition: all 0.4s;
    font-weight: 300;
  }

  &:last-of-type {
    border-bottom: 1px solid rgb(201, 201, 201);
  }

  &:hover {
    opacity: 0.5;

    h2 {
      transform: translateX(-10px);
    }

    p {
      transform: translateX(10px);
    }
  }

  @media (max-width: 570px) {
    padding: 50px 20px 50px 20px;
    h2 {
      font-size: 30px;
    }
  }
}
